import React from "react";

const AngleSlider = ({ modelAnge, onChange }) => {
  return (
    <div className="angleSlider">
      <img src="img/front_chair.png" onClick={() => onChange(Math.PI)} />
      <img src="img/right_chair.png" onClick={() => onChange(Math.PI * 1.25)} />
      <img src="img/back_chair.png" onClick={() => onChange(Math.PI * 2)} />
      <img src="img/left_chair.png" onClick={() => onChange(Math.PI * 2.5)} />
    </div>
  );
};

export default AngleSlider;
