import React from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "react-three-fiber";
import { useRef, useEffect } from "react";

const initColor = (parent, type, mtl) => {
  parent.traverse((o) => {
    if (o.isMesh && o.name.includes(type)) {
      o.castShadow = true;
      o.receiveShadow = true;
      o.material = mtl;
      o.nameID = type;
    }
  });
};

const ChairMesh = ({ newMaterial }) => {
  const { scene: theModel } = useLoader(GLTFLoader, "chair.gltf");
  const chair = useRef(theModel);

  useEffect(() => void setMaterial("cushions", newMaterial), [newMaterial]);

  useEffect(() => {
    if (!theModel) return;
    const woodTXT = new THREE.TextureLoader().load("img/wood_.jpg");
    const fabricTXT = new THREE.TextureLoader().load("img/fabric_.jpg");

    const INITIAL_MTL_LEGS = new THREE.MeshPhongMaterial({
      color: new THREE.Color(0xf1f1f1),
      shininess: 10,
      map: woodTXT
    });

    const INITIAL_MTL = new THREE.MeshPhongMaterial({
      color: new THREE.Color(0xf1f1f1),
      shininess: 10,
      map: fabricTXT
    });

    const INITIAL_MAP = [
      { childID: "back", mtl: INITIAL_MTL_LEGS },
      { childID: "base", mtl: INITIAL_MTL_LEGS },
      { childID: "cushions", mtl: INITIAL_MTL },
      { childID: "legs", mtl: INITIAL_MTL_LEGS },
      { childID: "supports", mtl: INITIAL_MTL_LEGS }
    ];

    for (let object of INITIAL_MAP) {
      initColor(theModel, object.childID, object.mtl);
    }
  }, [theModel]);

  const setMaterial = (type, mtl) => {
    theModel.traverse((o) => {
      if (o.isMesh && o.nameID != null) {
        if (o.nameID === type) {
          o.material = mtl;
        }
      }
    });
  };

  return (
    <primitive
      name="modelObject"
      ref={chair}
      object={theModel}
      scale={[2.5, 2.5, 2.5]}
      rotation={[0, Math.PI, 0]}
      position={[0, -1.2, 0]}
      receiveShadow
      castShadow
    ></primitive>
  );
};

export default ChairMesh;
