import React from "react";

const Floor = () => {
  return (
    <mesh position={[0, -1, 0]} receiveShadow rotation={[-0.5 * Math.PI, 0, 0]}>
      <planeGeometry args={[5000, 5000, 1, 1]} />
      <shadowMaterial opacity={0.2} />
    </mesh>
  );
};

export default Floor;
