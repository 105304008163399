import React, { useState } from "react";
import "./App.css";
import { Scene } from "./canvas/Scene";
import { Canvas } from "react-three-fiber";
import ModelConfig from "./containers/ModelConfig/ModelConfig";
import ViewerAr from "./containers/ViewerAr/ViewerAr";
import AngleSlider from "./components/AngleSlider";

const App = () => {
  const [selectedConfig, setConfig] = useState({
    color: "255761",
    texture: "img/fabric_.jpg"
  });

  const [modelAngle, setAngle] = useState(0);

  const changeConfig = ({ color, texture, size, shininess }) =>
    setConfig((prevState) => ({
      ...prevState,
      ...(color && { color }),
      ...(texture && { texture, size, shininess })
    }));

  const changeAngle = (value) => {
    setAngle(value);
    setTimeout(() => setAngle(0), 500);
  };

  return (
    <div className="container">
      <div className="leftWrapper">
        <div className="modelContainer">
          <Canvas id="rtfCanvas">
            <Scene selectedConfig={selectedConfig} modelAngle={modelAngle} />
          </Canvas>
          <div className="controls">
            <div className="info">
              <div className="info__message">
                <p>
                  <strong>&nbsp;Grab&nbsp;</strong> to rotate table.{" "}
                  <strong>&nbsp;Scroll&nbsp;</strong> to zoom.{" "}
                  <strong>&nbsp;Drag&nbsp;</strong> swatches to view more.
                </p>
              </div>
            </div>
          </div>
          <ViewerAr />
        </div>
        <AngleSlider onChange={changeAngle} />
      </div>
      <ModelConfig
        onChangeConfig={changeConfig}
        selectedConfig={selectedConfig}
      />
    </div>
  );
};

export default App;
