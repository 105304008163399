import React from "react";
import "./styles.css";
import { TEXTURE_COLORS } from "../../constants/colors";

const ModelConfig = ({ onChangeConfig, selectedConfig }) => {
  return (
    <div className="section">
      <h2 className="sectionTitle">Armchair</h2>
      <p className="price">Price: $999</p>
      <div className="config">
        <div className="configItem">
          <p className="configItemTitle">Select Texture</p>
          <div className="configItemList">
            {TEXTURE_COLORS.map(({ texture, size, shininess, colors }) => (
              <div
                key={texture}
                className={`${
                  selectedConfig.texture === texture ? "selectedOption" : ""
                }`}
                style={{ background: `url(${texture})` }}
                onClick={() =>
                  onChangeConfig({
                    texture,
                    size,
                    shininess,
                    color: colors[0].color
                  })
                }
              />
            ))}
          </div>
        </div>
        <div className="configItem">
          <p className="configItemTitle">Select Color</p>
          <div className="configItemList">
            {TEXTURE_COLORS.find(
              ({ texture }) => selectedConfig.texture === texture
            ).colors.map(({ color }) => (
              <div
                key={color}
                className={`${
                  selectedConfig.color === color ? "selectedOption" : ""
                }`}
                style={{ background: `#${color}` }}
                onClick={() => onChangeConfig({ color })}
              />
            ))}
          </div>
        </div>
      </div>
      <span className="sectionLabel">
        3D Configurator displays different textures 
        and colors of a furniture, initiating high 
        user engagement, reducing website contents
        and accelerating buying cycle.
      </span>
    </div>
  );
};

export default ModelConfig;
