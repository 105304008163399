import { extend, useThree } from "react-three-fiber";
import React, { Suspense, useEffect, useMemo, useRef } from "react";
import ChairMesh from "./components/ChairMesh";
import Floor from "./components/Floor";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";
import * as THREE from "three";

extend({ OrbitControls });

export const Scene = ({ selectedConfig, modelAngle }) => {
  const {
    scene,
    camera,
    gl: { domElement, shadowMap }
  } = useThree();
  const orbitControls = useRef(null);

  // Scene configuration;
  useEffect(() => {
    const directionalLight = scene.children[1];
    camera.fov = 50;
    directionalLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
    shadowMap.enabled = true;
  }, []);

  useEffect(() => {
    if (scene.getObjectByName("modelObject") && modelAngle !== 0) {
      gsap.to(scene.getObjectByName("modelObject").rotation, {
        duration: 1,
        delay: 0,
        y: modelAngle
      });
      gsap.set(camera.position, {
        x: 0,
        y: 0,
        z: 5
      });
      camera.updateProjectionMatrix();
      orbitControls.current.update();
    }
  }, [modelAngle]);

  const modelMaterial = useMemo(() => {
    const { color, texture, size, shininess } = selectedConfig;

    let newMTL = new THREE.MeshPhongMaterial({
      shininess: 10
    });
    let newColor = color;

    let txt = new THREE.TextureLoader().load(texture);

    if (size) txt.repeat.set(size[0], size[1], size[2]);
    if (shininess) newMTL.shininess = shininess;
    txt.wrapS = THREE.RepeatWrapping;
    txt.wrapT = THREE.RepeatWrapping;

    newMTL.map = txt;

    newMTL.color.setHex(parseInt("0x" + newColor));

    return newMTL;
  }, [selectedConfig]);

  return (
    <>
      <orbitControls ref={orbitControls} args={[camera, domElement]} />
      <hemisphereLight
        skycolor={new THREE.Color(0xffffff)}
        groundColor={new THREE.Color(0xffffff)}
        intensity={0.61}
        position={[0, 50, 0]}
      />
      <directionalLight
        color={new THREE.Color(0xffffff)}
        intensity={0.54}
        position={[-8, 12, 8]}
        castShadow
      />
      <Suspense fallback={null}>
        <ChairMesh newMaterial={modelMaterial} />
        <Floor />
      </Suspense>
    </>
  );
};
